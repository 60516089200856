import { PageTitles, PrimaryFilters, PrimaryView } from '@revelio/core';
import { View } from '@revelio/data-access';
import {
  FilterMenuLimits,
  FilterSets,
  GEOGRAPHY_GRANULARITY_FILTERS,
  LocalSelectionCategories,
  PrimaryFilterLimits,
  RICS_FILTERS,
  ROLE_GRANULARITY_FILTERS,
  SKILL_GRANULARITY_FILTERS,
  SelectionCategories,
  ViewTypes,
} from '@revelio/filtering';

import { CompositionMigration } from '../../composition-migration';

const primaryViewFilters = [
  {
    filters: ROLE_GRANULARITY_FILTERS,
    isNested: true,
  },
];

export function RoleCompositions() {
  const SHARED_SELECTABLE_FILTERS = [
    SelectionCategories.HIGHEST_DEGREE,
    SelectionCategories.ETHNICITY,
    { filters: RICS_FILTERS, isNested: true, limit: 10 },
    SelectionCategories.GENDER,
    {
      filters: GEOGRAPHY_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    {
      filters: SKILL_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    SelectionCategories.SENIORITY,
    SelectionCategories.KEYWORD,
    SelectionCategories.RAW_TITLE,
    LocalSelectionCategories.DATA_METRIC as unknown as SelectionCategories,
  ];
  const selectableFilters = {
    snapshot: [
      SelectionCategories.SAVED_FILTER_SET,
      SelectionCategories.SNAPSHOT_DATE,
      ...SHARED_SELECTABLE_FILTERS,
    ],
    overtime: [
      SelectionCategories.SAVED_FILTER_SET,
      SelectionCategories.DATE_RANGE,
      ...SHARED_SELECTABLE_FILTERS,
    ],
  };
  return (
    <CompositionMigration
      titleV1={[PageTitles.ROLE, PageTitles.COMPOSITIONS]}
      titleV2={[PageTitles.ROLE, PageTitles.COMPOSITIONS_V2]}
      primaryView={PrimaryView.ROLE}
      savedSetView={View.Role}
      viewType={ViewTypes.ROLE}
      primaryFilter={PrimaryFilters.ROLE}
      primaryViewFilterLimit={PrimaryFilterLimits.ROLE_OVERVIEW}
      filterMenuLimit={FilterMenuLimits.ROLE_OVERVIEW}
      primaryViewFilters={primaryViewFilters}
      selectableFilters={selectableFilters}
      sharedFilterSetId={FilterSets.ROLE}
      filterSet={FilterSets.ROLE_COMPOSITIONS}
    />
  );
}

export default RoleCompositions;
