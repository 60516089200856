export const AiSearchIcon = () => {
  return (
    <svg
      width="11"
      height="13"
      viewBox="0 0 11 13"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.40501 0.476341C8.43516 0.384664 8.56484 0.384664 8.59499 0.476341L9.10294 2.02081C9.11286 2.05098 9.13652 2.07464 9.16669 2.08456L10.7112 2.59251C10.8028 2.62266 10.8028 2.75234 10.7112 2.78249L9.16669 3.29044C9.13652 3.30036 9.11286 3.32402 9.10294 3.35419L8.59499 4.89866C8.56484 4.99034 8.43516 4.99034 8.40501 4.89866L7.89706 3.35419C7.88714 3.32402 7.86348 3.30036 7.83331 3.29044L6.28884 2.78249C6.19716 2.75234 6.19716 2.62266 6.28884 2.59251L7.83331 2.08456C7.86348 2.07464 7.88714 2.05098 7.89706 2.02081L8.40501 0.476341Z"
        fill="#2D426A"
      />
      <path
        d="M8.40501 0.476341C8.43516 0.384664 8.56484 0.384664 8.59499 0.476341L9.10294 2.02081C9.11286 2.05098 9.13652 2.07464 9.16669 2.08456L10.7112 2.59251C10.8028 2.62266 10.8028 2.75234 10.7112 2.78249L9.16669 3.29044C9.13652 3.30036 9.11286 3.32402 9.10294 3.35419L8.59499 4.89866C8.56484 4.99034 8.43516 4.99034 8.40501 4.89866L7.89706 3.35419C7.88714 3.32402 7.86348 3.30036 7.83331 3.29044L6.28884 2.78249C6.19716 2.75234 6.19716 2.62266 6.28884 2.59251L7.83331 2.08456C7.86348 2.07464 7.88714 2.05098 7.89706 2.02081L8.40501 0.476341Z"
        fill="#2D426A"
        fillOpacity="0.2"
      />
      <path
        d="M4.81001 3.39018C4.87031 3.20683 5.12969 3.20683 5.18999 3.39018L6.20588 6.47911C6.22572 6.53945 6.27305 6.58678 6.33339 6.60662L9.42232 7.62251C9.60567 7.68281 9.60567 7.94219 9.42232 8.00249L6.33339 9.01838C6.27305 9.03822 6.22572 9.08555 6.20588 9.14589L5.18999 12.2348C5.12969 12.4182 4.87031 12.4182 4.81001 12.2348L3.79412 9.14589C3.77428 9.08555 3.72695 9.03822 3.66661 9.01838L0.577682 8.00249C0.394328 7.94219 0.394327 7.68281 0.577682 7.62251L3.66661 6.60662C3.72695 6.58678 3.77428 6.53945 3.79412 6.47911L4.81001 3.39018Z"
        fill="#2D426A"
      />
      <path
        d="M4.81001 3.39018C4.87031 3.20683 5.12969 3.20683 5.18999 3.39018L6.20588 6.47911C6.22572 6.53945 6.27305 6.58678 6.33339 6.60662L9.42232 7.62251C9.60567 7.68281 9.60567 7.94219 9.42232 8.00249L6.33339 9.01838C6.27305 9.03822 6.22572 9.08555 6.20588 9.14589L5.18999 12.2348C5.12969 12.4182 4.87031 12.4182 4.81001 12.2348L3.79412 9.14589C3.77428 9.08555 3.72695 9.03822 3.66661 9.01838L0.577682 8.00249C0.394328 7.94219 0.394327 7.68281 0.577682 7.62251L3.66661 6.60662C3.72695 6.58678 3.77428 6.53945 3.79412 6.47911L4.81001 3.39018Z"
        fill="#2D426A"
        fillOpacity="0.2"
      />
    </svg>
  );
};
