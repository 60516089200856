import { ContactSalesButton } from '@revelio/composed';
import { PageTitles, PrimaryFilters, PrimaryView } from '@revelio/core';
import { View } from '@revelio/data-access';
import {
  FilterMenuLimits,
  FilterSets,
  GEOGRAPHY_GRANULARITY_FILTERS,
  LocalSelectionCategories,
  PrimaryFilterLimits,
  RICS_AND_COMPANY_FILTERS,
  ROLE_GRANULARITY_FILTERS,
  SKILL_GRANULARITY_FILTERS,
  SelectionCategories,
  ViewTypes,
} from '@revelio/filtering';

import { CompositionMigration } from '../../composition-migration';

export function CompanyCompositions() {
  const primaryViewFilter = [
    {
      filters: RICS_AND_COMPANY_FILTERS,
      isNested: true,
    },
  ];
  const SHARED_SELECTABLE_FILTERS = [
    SelectionCategories.HIGHEST_DEGREE,
    SelectionCategories.ETHNICITY,
    SelectionCategories.GENDER,
    {
      filters: ROLE_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    {
      filters: GEOGRAPHY_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    {
      filters: SKILL_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    SelectionCategories.SENIORITY,
    SelectionCategories.KEYWORD,
    SelectionCategories.RAW_TITLE,
    LocalSelectionCategories.DATA_METRIC as unknown as SelectionCategories,
  ];

  const selectableFilters = {
    snapshot: [
      SelectionCategories.SAVED_FILTER_SET,
      SelectionCategories.SNAPSHOT_DATE,
      ...SHARED_SELECTABLE_FILTERS,
    ],
    overtime: [
      SelectionCategories.SAVED_FILTER_SET,
      SelectionCategories.DATE_RANGE,
      ...SHARED_SELECTABLE_FILTERS,
    ],
  };

  return (
    <CompositionMigration
      titleV1={[PageTitles.COMPANY, PageTitles.COMPOSITIONS]}
      titleV2={[PageTitles.COMPANY, PageTitles.COMPOSITIONS_V2]}
      primaryView={PrimaryView.COMPANY}
      savedSetView={View.Company}
      viewType={ViewTypes.COMPANY}
      primaryFilter={PrimaryFilters.COMPANY}
      primaryViewFilterLimit={PrimaryFilterLimits.COMPANY_OVERVIEW}
      filterMenuLimit={FilterMenuLimits.COMPANY_OVERVIEW}
      primaryViewFilters={primaryViewFilter}
      selectableFilters={selectableFilters}
      sharedFilterSetId={FilterSets.COMPANY}
      filterSet={FilterSets.COMPANY_OVERVIEW}
      trialNoResultsMessage={
        <>
          Can't find a company?
          <ContactSalesButton
            buttonProps={{
              size: 'sm',
              variant: 'link',
              fontWeight: 400,
              paddingTop: 2,
              paddingLeft: 1,
            }}
            page={PageTitles.COMPOSITIONS}
          />
        </>
      }
    />
  );
}

export default CompanyCompositions;
