import { Box, Flex, Grid, GridItem, Link, Text } from '@chakra-ui/react';
import { flatten, startCase } from 'lodash';
import { ComponentProps, useEffect, useMemo, useState } from 'react';
import { pipe } from 'rxjs';
import { tap } from 'rxjs/operators';

import { useUserCurrency } from '@revelio/auth';
import { getPlotInfo } from '@revelio/composed';
import {
  AddEntityButtonText,
  PageTitles,
  PrimaryFilters,
  PrimaryView,
  TimeFrameView,
  TourClasses,
  Views,
} from '@revelio/core';
import { D3ChartNames } from '@revelio/d3';
import { View } from '@revelio/data-access';
import {
  CompositionSupportedViewTypes,
  DefaultDates,
  EndpointSegment,
  FilterChips,
  FilterChipsContainer,
  FilterContainer,
  FilterItem,
  FilterList,
  FilterMenu,
  FilterMenuItemOrConfig,
  FilterMenuLimits,
  FilterSetSaveMenu,
  FilterSets,
  FiltersUsedInTabs,
  LocalSelectionCategories,
  OtherFilterNames,
  PrimaryDataView,
  PrimaryEntityPopoutTreeFilter,
  PrimaryFilterLimits,
  SHARED_SET_ENTITY_LIMIT,
  SelectFilter,
  SelectableFiltersMenuProp,
  SelectionCategories,
  SubFilterNames,
  TabsFilter,
  ValidValueTypes,
  ValueItem,
  ViewTypes,
  createSelectableFiltersMap,
  getNestedEntity,
  getPrimaryDataView,
  provideBasePlotConfigDefaults,
  upsertFilter,
  useAdaptiveRoleTaxonomy,
  useDefaultLastMonth,
  useManyPreFetchPlotConfigProviders,
  usePrimaryFilter,
  useSelectionLists,
  useSingleOrMoreFilterState,
  useStoredFilterSet,
  useSyncFiltersToSearchParamsPure,
  useTabMeta,
  useViewFilterDefaults,
  useViewFilters,
} from '@revelio/filtering';
import { Format, FormatType } from '@revelio/replots';

import { useTrackPerformance } from '../../hooks/mixpanel/useTrackPerformance';
import { PlotCard } from '../../shared/components/plot-card/plot-card';
import DashboardPage from '../DashboardPage';
import { canCompositionTourContinue } from '../tour/composition-page-step-custom-tooltip';
import { BasePlotConfigLookup } from './base-plots.config';
import { BaseSmallPlotConfigLookup } from './base-small-plots.config';
import {
  OverTimeChartPropsLookup,
  SnapshotChartPropsLookup,
} from './chart-props.config';
import { CompositionBottomPlot } from './composition-bottom-plot';
import { CompositionTopPlot } from './composition-top-plots';
import { useCompositionDataFetch, useGetQueryFilters } from './data-fetch';
import { useIsLegacyDownloadUser, useLegacyDataDownloader } from './utils';
import {
  BottomConfigMapperBase,
  TopPlotName,
  getChartDownloadData,
} from './utils';
import { getPlotData } from './utils/get-chart-data';

export interface OverviewProps {
  title: PageTitles[];
  viewType: CompositionSupportedViewTypes;
  primaryFilter: PrimaryFilters;
  primaryView: PrimaryView;
  primaryViewFilters: FilterMenuItemOrConfig[];
  primaryViewFilterLimit: PrimaryFilterLimits;
  filterMenuLimit: FilterMenuLimits;
  selectableFilters: SelectableFiltersMenuProp;
  sharedFilterSetId?: FilterSets;
  filterSet: FilterSets;
  trialNoResultsMessage?: JSX.Element;
  savedSetView: View;
}

export function Overview({
  title,
  viewType,
  primaryFilter,
  primaryView,
  primaryViewFilters,
  primaryViewFilterLimit,
  filterMenuLimit,
  selectableFilters,
  sharedFilterSetId = FilterSets.NONE,
  filterSet,
  trialNoResultsMessage,
  savedSetView,
}: OverviewProps) {
  const view = Views.OVERVIEW;

  const primaryDataView: PrimaryDataView = useMemo(
    () => getPrimaryDataView(viewType),
    [viewType]
  );

  const dateRangeFilterId = SelectionCategories.DATE_RANGE;
  const smallPlotConfigs = useMemo(
    () => BaseSmallPlotConfigLookup[viewType],
    [viewType]
  );

  const sharesPlotConfigs = useMemo(
    () => BasePlotConfigLookup[viewType],
    [viewType]
  );

  const snapshotChartProps = useMemo(
    () => SnapshotChartPropsLookup[viewType],
    [viewType]
  );

  const overTimeChartProps = useMemo(
    () => OverTimeChartPropsLookup[viewType],
    [viewType]
  );

  const [isSnapshotState, setIsSnapshotState] = useState(true);
  const allFilters = Array.from(
    new Set([...selectableFilters.snapshot, ...selectableFilters.overtime])
  );

  const primaryEntity = getNestedEntity(viewType);
  const primaryFilters = useMemo(
    () => flatten(createSelectableFiltersMap(primaryViewFilters)),
    [primaryViewFilters]
  ) as SelectionCategories[];

  const selectableFiltersMap = createSelectableFiltersMap(allFilters);
  const flattenedSelectableFilters = flatten(selectableFiltersMap);

  const storedFilterSetArgs = {
    sharedSetId: sharedFilterSetId,
    tab: savedSetView,
    primaryEntitiesSync: true,
    limit: primaryViewFilterLimit,
    filterNames: primaryFilters,
    uniqueSetId: filterSet,
  };

  useStoredFilterSet(storedFilterSetArgs);

  useSelectionLists([
    ...primaryFilters,
    ...flattenedSelectableFilters,
    ...FiltersUsedInTabs,
    SelectionCategories.SKILL,
    SelectionCategories.JOB_CATEGORY,
    SelectionCategories.ROLE_K50,
    SelectionCategories.ROLE_K500,
    SelectionCategories.KEYWORD,
    SelectionCategories.RICS_K400,
  ]);
  useViewFilters([
    ...primaryFilters,
    // date filters are excluded because they are passed in as "additionalNonActiveFilters"
    // so the download endpoint is only called with either snapshot or date range depending on which plot is downloaded
    ...selectableFiltersMap.filter(
      (f) =>
        f !== SelectionCategories.SNAPSHOT_DATE &&
        f !== SelectionCategories.DATE_RANGE
    ),
  ]);

  useTabMeta({
    savedSetView,
    view,
    viewType,
    limit: PrimaryFilterLimits.OVERVIEW,
    supportPrimaryEntities: true,
    includeDisabledFilters: true,
    primaryFilters,
  });
  useViewFilterDefaults({
    view,
    viewType,
    presetView: sharedFilterSetId,
    onlyConsiderTheseFiltersToTriggerDefaults: [
      LocalSelectionCategories.PRIMARY_ENTITIES,
      ...Object.values(SubFilterNames),
    ],
    viewFilters: [
      LocalSelectionCategories.DATA_METRIC,
      LocalSelectionCategories.PRIMARY_ENTITIES,
    ],
    limit: PrimaryFilterLimits.OVERVIEW,
    dateKey: SelectionCategories.DATE_RANGE,
    primaryFilters,
    supportPrimaryEntities: true,
  });

  useDefaultLastMonth({
    view,
    viewType,
    dateType: DefaultDates.DEFAULT_LAST_MONTH,
    dateKey: SelectionCategories.DATE_RANGE,
  });

  useAdaptiveRoleTaxonomy({
    viewType,
    primaryFilters,
  });

  // grouped & subfilter never on snapshot
  // Over Time: subfilter & grouped is only on bottom 6, not allowed on top 6
  // useHiddenFiltersWithProvidedValues({
  //   [OtherFilterNames.GROUPED]: true,
  // });

  usePrimaryFilter(primaryFilter);

  const viewDefaultsForSmallPlots = provideBasePlotConfigDefaults({
    view,
    viewType: ViewTypes.SNAPSHOT,
    chartType: D3ChartNames.BarChartHorizontal,
    metaData: {
      requiredParams: [SelectionCategories.PRIMARY_FILTER],
      pageGroupName: 'compositions',
      primaryDataView: primaryDataView,
    },
    preFetchConfig: {
      viewType: ViewTypes.OVERTIME,
      chartType: D3ChartNames.LineChart,
    },
  });

  const {
    mappers: {
      currentConfigMappers: smallConfigMappers,
      preFetchConfigMappers: smallPreFetchConfigMappers,
    },
  } = useManyPreFetchPlotConfigProviders(
    smallPlotConfigs.map((sConfig) => {
      const { endpoint } = sConfig;
      return viewDefaultsForSmallPlots({
        ...sConfig,
        chartProps: snapshotChartProps[endpoint as EndpointSegment],
      });
    })
  );

  const viewDefaultsForPlots = provideBasePlotConfigDefaults({
    view,
    viewType: ViewTypes.SHARES_SNAPSHOT,
    chartType: D3ChartNames.StackedBarChartHorizontal,
    metaData: {
      requiredParams: [SelectionCategories.PRIMARY_FILTER],
      pageGroupName: 'compositions',
      primaryDataView: primaryDataView,
    },
    preFetchConfig: {
      viewType: ViewTypes.SHARES_OVERTIME,
      chartType: D3ChartNames.LineChart,
    },
  });

  const {
    mappers: { currentConfigMappers: configMappers, preFetchConfigMappers },
  } = useManyPreFetchPlotConfigProviders(
    sharesPlotConfigs.map((config) => {
      const { endpoint } = config;
      return viewDefaultsForPlots({
        ...config,
        endpoint: endpoint,
        chartProps: snapshotChartProps[endpoint as EndpointSegment],
      });
    })
  );

  useSingleOrMoreFilterState<SelectFilter<FilterList>[]>(
    primaryFilters,
    pipe(
      tap((filters) => {
        const numSelected = (filters as SelectFilter<FilterList>[]).reduce(
          (total, f) => {
            total = total + f.value.length;
            return total;
          },
          0
        );

        // TODO: uncomment below line when we refactor Grouped feature
        // setShowGroupedSwitch(numSelected == 1 ? true : false);
        if (numSelected > 1) {
          upsertFilter(OtherFilterNames.GROUPED, { value: true });
        }
      })
    )
  );

  useSingleOrMoreFilterState<SelectFilter<FilterItem<ValueItem>>>(
    LocalSelectionCategories.SNAPSHOT_OR_OVER_TIME,
    pipe(
      tap((filter) => {
        const singleFilter = filter as SelectFilter<FilterItem<ValueItem>>;
        if (singleFilter?.value.id) {
          const isSnapshot = singleFilter.value.id == ViewTypes.SNAPSHOT;

          setIsSnapshotState(isSnapshot);

          const newViewType = (cond: boolean) =>
            cond ? ViewTypes.SNAPSHOT : ViewTypes.OVERTIME;

          const updatedChartType = (cond: boolean) =>
            cond ? D3ChartNames.BarChartHorizontal : D3ChartNames.LineChart;

          const sharesViewType = (cond: boolean) =>
            cond ? ViewTypes.SHARES_SNAPSHOT : ViewTypes.SHARES_OVERTIME;

          const updatedSharesChartType = (cond: boolean) =>
            cond
              ? D3ChartNames.StackedBarChartHorizontal
              : D3ChartNames.LineChart;

          const chartPropsConfigLookup = (cond: boolean) =>
            cond ? snapshotChartProps : overTimeChartProps;

          smallConfigMappers.forEach(
            ({ endpointSegment, updater, metaData }, i) => {
              const preFetchUpdater = smallPreFetchConfigMappers[i].updater;

              const updatedAdditionalFilters = (cond: boolean) =>
                cond
                  ? [
                      SelectionCategories.PRIMARY_FILTER,
                      SelectionCategories.SNAPSHOT_DATE,
                    ]
                  : [SelectionCategories.PRIMARY_FILTER, dateRangeFilterId];

              const updatedBrokenoutFilterIds = (cond: boolean) =>
                /* eslint-disable-next-line no-nested-ternary */
                cond
                  ? [SelectionCategories.PRIMARY_FILTER]
                  : metaData?.isGqlQuery
                    ? [SelectionCategories.PRIMARY_FILTER]
                    : [SelectionCategories.PRIMARY_FILTER, dateRangeFilterId];

              updater.next({
                viewType: newViewType(isSnapshot),
                chartType: updatedChartType(isSnapshot),
                chartProps:
                  chartPropsConfigLookup(isSnapshot)[
                    endpointSegment as EndpointSegment
                  ],

                additionalNonActiveFilters:
                  updatedAdditionalFilters(isSnapshot),
                brokenOutFilterIds: updatedBrokenoutFilterIds(isSnapshot),
              });

              preFetchUpdater.next({
                viewType: newViewType(!isSnapshot),
                chartType: updatedChartType(!isSnapshot),
                chartProps:
                  chartPropsConfigLookup(!isSnapshot)[
                    endpointSegment as EndpointSegment
                  ],
                additionalNonActiveFilters:
                  updatedAdditionalFilters(!isSnapshot),
                brokenOutFilterIds: updatedBrokenoutFilterIds(!isSnapshot),
              });
            }
          );

          configMappers.forEach(
            ({ endpointSegment, updater, subfilters, metaData }, i) => {
              const preFetchUpdater = preFetchConfigMappers[i].updater;

              const SHARED_OVERTIME_ADDITIONAL_BROKENOUT_FILTERS = [
                SelectionCategories.PRIMARY_FILTER,
                OtherFilterNames.SUBFILTER,
                OtherFilterNames.GROUPED,
                subfilters.filterName,
              ];
              const updatedAdditionalFilters = (cond: boolean) =>
                cond
                  ? [
                      SelectionCategories.PRIMARY_FILTER,
                      SelectionCategories.SNAPSHOT_DATE,
                    ]
                  : [
                      ...SHARED_OVERTIME_ADDITIONAL_BROKENOUT_FILTERS,
                      dateRangeFilterId,
                    ];

              const updatedBrokenoutFilterIds = (cond: boolean) =>
                /* eslint-disable-next-line no-nested-ternary */
                cond
                  ? [SelectionCategories.PRIMARY_FILTER]
                  : metaData?.isGqlQuery
                    ? [...SHARED_OVERTIME_ADDITIONAL_BROKENOUT_FILTERS]
                    : [
                        ...SHARED_OVERTIME_ADDITIONAL_BROKENOUT_FILTERS,
                        dateRangeFilterId,
                      ];

              updater.next({
                viewType: sharesViewType(isSnapshot),
                chartType: updatedSharesChartType(isSnapshot),
                chartProps:
                  chartPropsConfigLookup(isSnapshot)[
                    endpointSegment as EndpointSegment
                  ],
                additionalNonActiveFilters:
                  updatedAdditionalFilters(isSnapshot),
                brokenOutFilterIds: updatedBrokenoutFilterIds(isSnapshot),
              });

              preFetchUpdater.next({
                viewType: sharesViewType(!isSnapshot),
                chartType: updatedSharesChartType(!isSnapshot),
                chartProps:
                  chartPropsConfigLookup(!isSnapshot)[
                    endpointSegment as EndpointSegment
                  ],
                additionalNonActiveFilters:
                  updatedAdditionalFilters(!isSnapshot),
                brokenOutFilterIds: updatedBrokenoutFilterIds(!isSnapshot),
              });
            }
          );
        }
      })
    )
  );

  const selectableFiltersByTime = isSnapshotState
    ? selectableFilters.snapshot
    : selectableFilters.overtime;

  const filtersForMenu = [...selectableFiltersByTime];

  type ConfigMapper = Omit<(typeof configMappers)[0], 'endpointSegment'> &
    BottomConfigMapperBase;
  const timeframe = isSnapshotState
    ? TimeFrameView.SNAPSHOT
    : TimeFrameView.OVERTIME;

  const {
    data,
    bottomPlots,
    lineColors,
    loading: compositionDataLoading,
    isQueryReady: isCompositionQueryReady,
  } = useCompositionDataFetch<ConfigMapper>({
    view: primaryView,
    timeframe: timeframe,
    primaryFilters,
    bottomConfigMapper: configMappers as ConfigMapper[],
  });

  useEffect(() => {
    canCompositionTourContinue.next(!compositionDataLoading);
  }, [compositionDataLoading]);

  useTrackPerformance({
    loading: compositionDataLoading,
    eventName: 'plot_page_performance',
  });

  const isLoading = compositionDataLoading || !isCompositionQueryReady;

  useSyncFiltersToSearchParamsPure({
    primaryFilters,
    syncToPrimaryEntities: true,
    isLoading,
  });

  const queryFilters = useGetQueryFilters({
    view: primaryView,
    timeframe: timeframe,
  });

  const isLegacyDownloadUser = useIsLegacyDownloadUser();
  const download = useLegacyDataDownloader();

  const { exchangeRate, code } = useUserCurrency();

  const topPlotMenuProps = ({
    plotName,
    format,
  }: {
    plotName: TopPlotName;
    format: Format | null;
  }): Pick<
    ComponentProps<typeof PlotCard>,
    'menu' | 'exportData' | 'plotShare' | 'slim' | 'onExport'
  > => {
    const isCurrencyFormat =
      format === FormatType.CURRENCY || format === FormatType.CURRENCY_INTEGER;

    return {
      slim: true,
      menu: ['expand', 'download-png', 'generate-link', 'download-data'],
      ...(!isLegacyDownloadUser
        ? {
            exportData: getChartDownloadData({
              entities: data?.composition,
              plotName,
            }),
          }
        : {}),
      onExport: async () => {
        if (isLegacyDownloadUser) {
          await download({
            view: primaryView,
            plotName,
            timeframe,
            filters: queryFilters,
          });
        }
      },
      plotShare: {
        data: getPlotData(
          timeframe,
          data?.composition,
          plotName,
          isCurrencyFormat,
          exchangeRate
        ),
        chartConfig: {
          chartType:
            timeframe === TimeFrameView.SNAPSHOT
              ? D3ChartNames.ReplotsBarChart
              : D3ChartNames.ReplotsLineChart,
          chartProps: {
            format: format ?? undefined,
            colors:
              timeframe === TimeFrameView.SNAPSHOT ? undefined : lineColors,
            currencyCode: isCurrencyFormat ? code : undefined,
          },
        },
      },
    };
  };

  return (
    <DashboardPage
      title={title}
      hideSelectionsMargins
      loading={isLoading}
      selections={
        <Flex
          justifyContent="flex-start"
          alignItems="center"
          flexDirection="row"
          wrap="wrap"
          rowGap="0.5rem"
        >
          <FilterChipsContainer
            filterNames={primaryFilters}
            showColors={!isSnapshotState}
            variant="companyChip"
            view={view}
            isPrimaryChip={true}
            min={1}
            limit={primaryViewFilterLimit}
            addButton={
              <Box className={TourClasses.TOUR_TRACKING_CLASS}>
                <PrimaryEntityPopoutTreeFilter
                  {...primaryEntity}
                  maxSelections={SHARED_SET_ENTITY_LIMIT}
                  minSelections={1}
                >
                  {AddEntityButtonText[primaryFilter]}
                </PrimaryEntityPopoutTreeFilter>
              </Box>
            }
          />
        </Flex>
      }
    >
      <FilterContainer
        flexDirection="row"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Flex
          justifyContent="flex-start"
          alignItems="flex-start"
          flexDirection="row"
          wrap="wrap"
          rowGap="0.5rem"
        >
          <FilterChips
            filterNames={allFilters} // maximum filter names across snapshot and overtime to avoid re-renders and removing chips
            variant="filterChip"
            limit={filterMenuLimit}
            viewType={viewType}
            propsView={view}
            showGranularity={true}
            filtersToIgnore={
              isSnapshotState
                ? [SelectionCategories.DATE_RANGE]
                : [SelectionCategories.SNAPSHOT_DATE]
            }
            addButton={
              <>
                <FilterMenu
                  title="Filter"
                  filters={filtersForMenu}
                  limit={filterMenuLimit}
                  selectMenuOpenDefault
                  endDateDefaultFilterName={DefaultDates.DEFAULT_LAST_MONTH}
                  viewIdForDefault={`${view}_${viewType}`}
                  viewType={viewType}
                />
                <FilterSetSaveMenu view={savedSetView} />
              </>
            }
          />
        </Flex>

        <Box className={TourClasses.TOUR_VIEW_CLASS}>
          <TabsFilter
            initialValue={{
              id: ViewTypes.SNAPSHOT,
              label: startCase(ViewTypes.SNAPSHOT),
            }}
            filterName={LocalSelectionCategories.SNAPSHOT_OR_OVER_TIME}
          ></TabsFilter>
        </Box>
      </FilterContainer>

      <Grid
        width="100%"
        height="100%"
        templateRows={'repeat(5, 1fr)'}
        templateColumns={'repeat(6, 1fr)'}
        gap={4}
        data-testid="plots-grid"
      >
        <GridItem minHeight={0}>
          <PlotCard
            title="Headcount"
            info={
              <Text variant="tooltip">
                Our estimate of the total workforce. These counts include both
                formal employees and contingent workers. These counts also
                include subsidiaries. More information on the methodologies used
                for this metric can be found{' '}
                <Link
                  href="https://www.data-dictionary.reveliolabs.com/methodology.html#sampling-weights"
                  isExternal
                  variant="tooltip"
                >
                  here
                </Link>
                .
              </Text>
            }
            infoPlacement="top-start"
            {...topPlotMenuProps({ plotName: 'headcount', format: null })}
          >
            <CompositionTopPlot
              data={data}
              timeFrame={timeframe}
              name="headcount"
              format={FormatType.SI}
              lineChartColors={lineColors}
            />
          </PlotCard>
        </GridItem>
        <GridItem minHeight={0}>
          <PlotCard
            title="Growth Rate"
            info={
              <Text variant="tooltip">
                The growth rate is the percent change in the total workforce. It
                is the difference between the hiring rate and attrition rate.
              </Text>
            }
            {...topPlotMenuProps({
              plotName: 'growth_rate',
              format: FormatType.PERCENTAGE,
            })}
          >
            <CompositionTopPlot
              data={data}
              timeFrame={timeframe}
              name="growth_rate"
              format={FormatType.PERCENTAGE}
              lineChartColors={lineColors}
            />
          </PlotCard>
        </GridItem>
        <GridItem minHeight={0}>
          <PlotCard
            title="Hiring Rate"
            info={
              <Text variant="tooltip">
                The annual rate at which workers join, relative to the total
                headcount. It is the 12-month moving sum of inflows divided by
                the 12-month moving average of headcount.
              </Text>
            }
            {...topPlotMenuProps({
              plotName: 'hiring_rate',
              format: FormatType.PERCENTAGE,
            })}
          >
            <CompositionTopPlot
              data={data}
              timeFrame={timeframe}
              name="hiring_rate"
              format={FormatType.PERCENTAGE}
              lineChartColors={lineColors}
            />
          </PlotCard>
        </GridItem>
        <GridItem minHeight={0}>
          <PlotCard
            title="Attrition Rate"
            info={
              <Text variant="tooltip">
                The annual rate at which workers leave, relative to the total
                headcount. It is the 12-month moving sum of outflows divided by
                the 12-month moving average of headcount.
              </Text>
            }
            {...topPlotMenuProps({
              plotName: 'attrition_rate',
              format: FormatType.PERCENTAGE,
            })}
          >
            <CompositionTopPlot
              data={data}
              timeFrame={timeframe}
              name="attrition_rate"
              format={FormatType.PERCENTAGE}
              lineChartColors={lineColors}
            />
          </PlotCard>
        </GridItem>
        <GridItem minHeight={0}>
          <PlotCard
            title="Tenure"
            info={
              <Text variant="tooltip">
                The average amount of time that workers have spent in their
                position.
              </Text>
            }
            {...topPlotMenuProps({
              plotName: 'tenure',
              format: FormatType.YEAR,
            })}
          >
            <CompositionTopPlot
              data={data}
              timeFrame={timeframe}
              name="tenure"
              format={FormatType.YEAR}
              lineChartColors={lineColors}
            />
          </PlotCard>
        </GridItem>
        <GridItem minHeight={0}>
          <PlotCard
            title="Salary"
            infoPlacement="top-end"
            info={
              <Text variant="tooltip">
                Our estimate of average salary. More information on the salary
                model can be found{' '}
                <Link
                  href="https://www.data-dictionary.reveliolabs.com/methodology.html#salary"
                  isExternal
                  variant="tooltip"
                >
                  here
                </Link>
                .
              </Text>
            }
            {...topPlotMenuProps({
              plotName: 'salary',
              format: FormatType.CURRENCY,
            })}
          >
            <CompositionTopPlot
              data={data}
              timeFrame={timeframe}
              name="salary"
              format={FormatType.CURRENCY}
              lineChartColors={lineColors}
            />
          </PlotCard>
        </GridItem>

        {/* Bottom Plots */}
        {bottomPlots.map((config, i) => (
          <GridItem
            key={i}
            gridArea={'span 2 / span 2 / span 2 / span 2'}
            className={`overview-plot-bottom-${
              config.endpointSegment
            } plot${i + 7}`}
          >
            <CompositionBottomPlot
              primaryView={primaryView}
              viewType={viewType}
              data={data}
              timeFrame={timeframe}
              queryFilters={queryFilters}
              format={
                timeframe === TimeFrameView.SNAPSHOT
                  ? undefined
                  : FormatType.PERCENTAGE
              }
              name={(() => {
                /* Temporary, needed to convert endpointSegment to bottomPlotName. Should be removed after subfilters are refactored */
                if (config.endpointSegment === EndpointSegment.ROLE) {
                  return 'job_categories';
                } else if (config.endpointSegment === EndpointSegment.GEO) {
                  return 'geographies';
                } else if (config.endpointSegment === EndpointSegment.SKILL) {
                  return 'skills';
                } else if (config.endpointSegment === EndpointSegment.GENDER) {
                  return 'genders';
                } else if (
                  config.endpointSegment === EndpointSegment.ETHNICITY
                ) {
                  return 'ethnicities';
                } else if (
                  config.endpointSegment === EndpointSegment.EDUCATION
                ) {
                  return 'educations';
                } else if (
                  config.endpointSegment === EndpointSegment.INDUSTRY
                ) {
                  return 'industries';
                }
                return config.endpointSegment;
              })()}
              title={config.name}
              info={
                getPlotInfo(view, config.endpointSegment as EndpointSegment, [
                  viewType,
                ]).plotInfoBody
              }
              infoPlacement={(() => {
                if (i % 3 === 0) return 'top-start';
                if (i % 3 === 2) return 'top-end';
                return undefined;
              })()}
              lineChartColors={lineColors}
              subfilter={{
                placementOverride: config.subfilters.placement,
                subfiltersMap: config.subfilters.subfiltersMap,
                defaultState: config.subfilters.default as Partial<
                  SelectFilter<FilterList<ValidValueTypes>>
                >,
                filterName: config.subfilters.filterName,
                selectionLists: config.subfilters.selectionLists,
              }}
            />
          </GridItem>
        ))}
      </Grid>
    </DashboardPage>
  );
}

export default Overview;
