import { User } from '@revelio/data-access';

/**
 * Extracts specific user properties to expose to Unleash feature flag service.
 * Only includes properties that are safe and relevant for feature flag targeting.
 *
 * @param user - User from \@revelio/data-access, or undefined if no user
 * @returns An object mapping property names to string values. Returns empty object if no user provided.
 */
export const unleashUserProperties = (
  user: User | undefined
): Record<string, string> => {
  if (!user) return {};

  const pickProperties: {
    userProperty: keyof User;
    rename?: string;
    filter?: (value: string) => boolean;
  }[] = [
    { userProperty: 'id', rename: 'userId' },
    { userProperty: 'client_name', rename: 'client' },
    { userProperty: 'role' },
    { userProperty: 'live' },
    { userProperty: 'active' },
    {
      userProperty: 'email',
      rename: 'revelioEmail',
      filter: (email: string) => email.includes('@reveliolabs.com'),
    },
  ];

  return pickProperties.reduce<Record<string, string>>(
    (acc, { userProperty, rename, filter }) => {
      const unleashProperty = rename ?? userProperty;
      const filterFn = filter ?? (() => true);
      const value = user[userProperty];

      let unleashValue = '';
      if (value === null || value === undefined) {
        unleashValue = '';
      } else if (typeof value === 'boolean') {
        unleashValue = value ? 'true' : 'false';
      } else if (typeof value === 'string') {
        unleashValue = value;
      } else {
        unleashValue = String(value);
      }
      if (!filterFn(unleashValue)) {
        unleashValue = ''; // remove property if it donsn't pass filter check
      }
      return { ...acc, [unleashProperty]: unleashValue };
    },
    {}
  );
};
