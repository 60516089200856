import { useState } from 'react';

import {
  TalentDiscoveryAiFilterSearchResponse,
  TalentDiscoveryAiSearchFilterType,
} from '@revelio/data-access';
import { RevelioTooltip } from '@revelio/layout';

interface Props {
  prompt: string;
  response: TalentDiscoveryAiFilterSearchResponse;
  textAreaRef: React.RefObject<HTMLTextAreaElement>;
}

export const ResponseHighlightedOverlay = ({
  prompt,
  response,
  textAreaRef,
}: Props) => {
  const segments = getHighlightedSegments({ prompt, response });

  const [hoveredPromptIndex, setHoveredPromptIndex] = useState<number | null>(
    null
  );

  const handleClick = (index: number) => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
      textAreaRef.current.setSelectionRange(index, index);
    }
  };

  return (
    <span
      style={{
        userSelect: 'none',
      }}
      onClick={() => hoveredPromptIndex && handleClick(hoveredPromptIndex)}
    >
      {prompt.split('').map((char, index) => {
        const matchingSegments = segments.filter(
          (segment) => index >= segment.startIndex && index < segment.endIndex
        );

        const isMatched = !matchingSegments.some(
          (segment) => !segment.isMatched
        );

        const matchingSegment = matchingSegments.find(
          (segment) => segment.isMatched === isMatched
        );

        // Determine if this is a partial match
        const isPartialMatch = matchingSegment?.isPartialMatch === true;

        const style = matchingSegment
          ? {
              borderBottom: getBorderBottom(isMatched, isPartialMatch),
              color: getTextColor(isMatched, isPartialMatch),
              cursor: 'text',
              fontWeight: '400',
            }
          : { cursor: 'text' };

        const sharedHighlightCharProps = {
          style,
          onMouseOver: (e: React.MouseEvent) => {
            e.stopPropagation();
            setHoveredPromptIndex(index);
          },
          onMouseLeave: (e: React.MouseEvent) => {
            e.stopPropagation();
            setHoveredPromptIndex(null);
          },
        };

        if (
          matchingSegment?.explanation &&
          Math.floor(
            (matchingSegment.startIndex + matchingSegment.endIndex) / 2
          ) === index
        ) {
          return (
            <RevelioTooltip
              key={index}
              label={matchingSegment?.explanation}
              isDisabled={!matchingSegment?.explanation}
              isOpen={
                hoveredPromptIndex !== null &&
                matchingSegment.startIndex < hoveredPromptIndex &&
                matchingSegment.endIndex > hoveredPromptIndex
              }
            >
              <span {...sharedHighlightCharProps}>{char}</span>
            </RevelioTooltip>
          );
        }

        return (
          <span key={index} {...sharedHighlightCharProps}>
            {char}
          </span>
        );
      })}
    </span>
  );
};

interface HighlightInfo {
  text: string; // only for debugging purposes to make it more readable when logging segments
  isMatched: boolean;
  isPartialMatch?: boolean; // New flag for partially matched segments
  startIndex: number;
  endIndex: number;
  explanation?: string;
}

const getHighlightedSegments = ({
  prompt,
  response,
}: {
  prompt: string;
  response: TalentDiscoveryAiFilterSearchResponse;
}): HighlightInfo[] => {
  const segments: HighlightInfo[] = [];
  const matchedPromptTexts = new Set<string>();

  // Helper function to process a single filter
  const processFilter = ({
    relevantPromptText,
  }: TalentDiscoveryAiSearchFilterType) => {
    const index = prompt
      .toLowerCase()
      .indexOf(relevantPromptText.toLowerCase());

    if (index !== -1) {
      segments.push({
        text: relevantPromptText,
        isMatched: true,
        startIndex: index,
        endIndex: index + relevantPromptText.length,
      });

      // Track matched prompt texts to detect partial matches later
      matchedPromptTexts.add(relevantPromptText.toLowerCase());
    }
  };

  // Process all known filters
  Object.entries(response.filters).forEach(([filterType, filterValue]) => {
    if (!filterValue) return;

    if (Array.isArray(filterValue)) {
      // Handle nested arrays (like skills and keywords)
      if (filterValue.length > 0 && Array.isArray(filterValue[0])) {
        (filterValue as TalentDiscoveryAiSearchFilterType[][]).forEach(
          (filterGroup) => {
            filterGroup.forEach(processFilter);
          }
        );
      } else {
        // Handle regular arrays
        filterValue.forEach((v) =>
          processFilter(v as TalentDiscoveryAiSearchFilterType)
        );
      }
    } else {
      // Handle single filters (like RangeFilter)
      processFilter(filterValue as TalentDiscoveryAiSearchFilterType);
    }
  });

  // Handle unknown filters
  response.unknownFilters?.forEach(({ relevantPromptText, explanation }) => {
    const index = prompt
      .toLowerCase()
      .indexOf(relevantPromptText.toLowerCase());

    if (index !== -1) {
      // Check if this is a partial match (exists in both filters and unknownFilters)
      const isPartialMatch = matchedPromptTexts.has(
        relevantPromptText.toLowerCase()
      );

      segments.push({
        text: relevantPromptText,
        isMatched: false,
        isPartialMatch, // Set the partial match flag
        explanation: explanation,
        startIndex: index,
        endIndex: index + relevantPromptText.length,
      });
    }
  });

  return segments;
};

// Helper functions to avoid nested ternaries
const getBorderBottom = (
  isMatched: boolean,
  isPartialMatch: boolean
): string | undefined => {
  if (isMatched) {
    return undefined;
  }
  return isPartialMatch ? '1px solid #FF9633' : '1px solid #EE002B';
};

const getTextColor = (isMatched: boolean, isPartialMatch: boolean): string => {
  if (isMatched) {
    return '#16BD5E';
  }
  return isPartialMatch ? '#FF9633' : '#EE002B';
};
