import { Box } from '@chakra-ui/layout';

import { useUserCurrency } from '@revelio/auth';
import { TimeFrameView } from '@revelio/core';
import { CompositionV2DataQuery } from '@revelio/data-access';
import { BarChart, Format, FormatType, LineChart } from '@revelio/replots';

import { adjustLineDataToUserCurrency } from '../compensation/data-fetch/get-overtime-data';
import {
  TopPlotName,
  adjustBarDataToUserCurrency,
  getReplotsBarDataV2,
  getReplotsLineDataV2,
} from './utils/get-chart-data';

export const CompositionTopPlotV2 = (props: {
  data: CompositionV2DataQuery | undefined;
  name: TopPlotName;
  timeFrame: TimeFrameView;
  format?: Format;
  lineChartColors: string[];
}) => {
  const { exchangeRate, code } = useUserCurrency();
  const isCurrencyFormat =
    props.format === FormatType.CURRENCY ||
    props.format === FormatType.CURRENCY_INTEGER;

  if (props.timeFrame === TimeFrameView.SNAPSHOT) {
    const barData = getReplotsBarDataV2({
      entities: props.data?.composition_v2?.entities ?? [],
      plotName: props.name,
    });

    const adjustedBarData = isCurrencyFormat
      ? adjustBarDataToUserCurrency(barData, exchangeRate)
      : barData;

    return (
      <Box width="100%" height="100%" padding={'8px 0'}>
        <BarChart
          data={adjustedBarData}
          format={props.format}
          currencyCode={code}
          highlightOnHover
        />
      </Box>
    );
  }

  const lineData = getReplotsLineDataV2({
    entities: props.data?.composition_v2?.entities ?? [],
    plotName: props.name,
    months: props.data?.composition_v2?.refs?.months ?? [],
  });

  const adjustedLineChartsData = isCurrencyFormat
    ? adjustLineDataToUserCurrency(lineData, exchangeRate)
    : lineData;

  return (
    <Box width="100%" height="100%" padding={'4px 0 0'}>
      <LineChart
        data={adjustedLineChartsData}
        format={props.format}
        dateFormat="month"
        colors={props.lineChartColors}
        currencyCode={code}
      />
    </Box>
  );
};
