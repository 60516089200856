import { SeriesPoint, scaleBand, scaleLinear, scaleOrdinal, stack } from 'd3';
import { clamp, min } from 'lodash';

import { useResizeObserver } from '@revelio/core';

import { PatternDiagonalStripe } from '../../svg-patterns/patterns';
import { CommonPlotProps, FormatType } from '../../types';
import { getFormatter, getMaxTextWidth, plotColors } from '../../utilities';
import { AxisLabel } from '../axis/axis-label';
import { Legend } from '../legend/legend';
import { PlotLoadWrapper } from '../plot-loader/plot-loader';
import styles from './stacked-bar-chart-horizontal.module.css';
import { StackedBarTooltip } from './stacked-bar-tooltip';

const Dimensions = {
  BarLeftPadding: 5,
  MaxBarHeight: 40,
  MinBarHeight: 10,
  BarYSpacingFactor: 0.2,
  BarXSpacing: 2,
  LegendHeight: 42,
};

export type StackedBarData = {
  label: string;
  segments: Record<string, number>;
};

export type StackedBarChartHorizontalProps = {
  data: StackedBarData[];
  format?: FormatType;
  colorOverride?: Record<string, string>;
  hideLegend?: boolean;
  formatLegendLabel?: (label: string) => string;
  maxLabelWidth?: number;
} & CommonPlotProps;

export const StackedBarChartHorizontal = ({
  data = [],
  loading = false,
  format = FormatType.INTEGER,
  colorOverride,
  hideLegend,
  maxLabelWidth = 75,
  formatLegendLabel = (label) => label,
}: StackedBarChartHorizontalProps) => {
  const { containerRef, width, height } = useResizeObserver();

  /* Plot dimensions */
  const labelWidth = min([
    getMaxTextWidth({
      texts: [...data.map((bar) => bar.label)],
      fontSize: 11,
      fontFamily: '"Source Sans Pro", sans-serif',
    }),
    maxLabelWidth,
  ]) as number;
  const legendHeight = hideLegend ? 0 : Dimensions.LegendHeight;
  const minHeight = data.length * Dimensions.MinBarHeight;
  const maxHeight = data.length * Dimensions.MaxBarHeight;
  const plotHeight = clamp(height - legendHeight, minHeight, maxHeight);
  const plotWidth = Math.max(0, width - labelWidth - Dimensions.BarLeftPadding);

  const isPlotSizeValid = plotWidth > 0 && plotHeight > 0;
  const shouldHideLegend = hideLegend || legendHeight + plotHeight > height;

  /* Data transformation */
  const segmentLabels = Array.from(
    new Set(data.flatMap((bar) => Object.keys(bar.segments)))
  );
  const emptyBars = data.filter((bar) =>
    Object.values(bar.segments).every((value) => value === 0)
  );
  const emptyBarPatternId = 'diagonal-stripe-4';
  const stackedData = stack<StackedBarData>()
    .keys(segmentLabels)
    .value((d, key) => {
      const total = Object.values(d.segments).reduce((sum, v) => sum + v, 0);
      return d.segments[key] / total || 0;
    })(data);

  /* Scales */
  const xScale = scaleLinear().domain([0, 1]).range([0, plotWidth]);
  const yScale = scaleBand(
    data.map((d) => d.label),
    [0, plotHeight]
  )
    .paddingOuter(Dimensions.BarYSpacingFactor)
    .paddingInner(Dimensions.BarYSpacingFactor);

  const barHeight = yScale.bandwidth();

  const overridenColors = Object.values(colorOverride ?? {});
  const colors = plotColors.filter((color) => !overridenColors.includes(color));
  const labelColors = segmentLabels.map((label, index) => ({
    label,
    color: colorOverride?.[label] ?? colors[index],
  }));

  const colorScale = scaleOrdinal<string>()
    .domain(labelColors.map(({ label }) => label))
    .range(labelColors.map(({ color }) => color))
    .unknown('#A0A9B8');

  const formatMain = getFormatter(format);
  const formatSecondary = getFormatter(FormatType.PERCENTAGE);

  const renderBarSegment = ({
    series,
    segment,
  }: {
    series: d3.Series<StackedBarData, string>;
    segment: SeriesPoint<StackedBarData>;
  }) => {
    const x = xScale(segment[0]);
    const y = yScale(segment.data.label);

    if (!y) return null;

    const width = Math.max(
      0,
      xScale(segment[1]) - xScale(segment[0]) - Dimensions.BarXSpacing
    );
    const color = colorScale(series.key);
    const entries = series.map((s) => ({
      active: s.data.label === segment.data.label,
      label: s.data.label,
      mainValue: formatMain(s.data.segments[series.key]),
      secondaryValue: formatSecondary(s[1] - s[0]),
    }));

    if (width === 0) {
      return null;
    }
    return (
      <g key={`segment-${series.key}-${segment.data.label}`}>
        <StackedBarTooltip title={series.key} entries={entries}>
          <g>
            <rect
              x={x}
              y={y}
              fill={color}
              width={width}
              height={barHeight}
              className={styles.segment}
              rx={2}
              cursor={'pointer'}
            />
          </g>
        </StackedBarTooltip>
      </g>
    );
  };

  const noData =
    !data ||
    data?.length === 0 ||
    data.every((metric) => Object.values(metric.segments).length === 0) ||
    data.every((metric) =>
      Object.values(metric.segments).every((value) => value === 0)
    );

  return (
    <div
      data-testid="plot-StackedBarChartHorizontal"
      ref={containerRef}
      className={styles.container}
    >
      <PlotLoadWrapper loading={loading} noData={noData}>
        <div style={{ width: '100%' }}>
          <svg width="100%" height={plotHeight}>
            <defs>
              <PatternDiagonalStripe patternId={emptyBarPatternId} />
            </defs>
            <g id="y-axis">
              {isPlotSizeValid &&
                yScale.domain().map((domain, index) => {
                  const y = yScale(domain);
                  return (
                    y && (
                      <AxisLabel
                        testId="stacked-horizontal-bar-chart-y-axis-label"
                        key={`axis-${domain}-${index}`}
                        y={y + barHeight / 2}
                        width={labelWidth}
                        availableHeight={barHeight + 2}
                        label={domain}
                      />
                    )
                  );
                })}
            </g>
            <g
              id="chart"
              transform={`translate(${labelWidth + Dimensions.BarLeftPadding}, 0)`}
            >
              {isPlotSizeValid && (
                <g className={styles.barContainer}>
                  {stackedData.map((series) => {
                    return (
                      <g
                        key={`series-${series.key}`}
                        fill={colorScale(series.key) ?? '#A0A9B8'}
                        className={styles.bar}
                      >
                        {series.map((segment, i) =>
                          renderBarSegment({ series, segment })
                        )}
                      </g>
                    );
                  })}
                  {emptyBars.map((emptyBar) => {
                    return (
                      <g
                        key={`empty-bar-${emptyBar.label}`}
                        className={styles.bar}
                      >
                        <StackedBarTooltip
                          title={emptyBar.label}
                          entries={undefined}
                        >
                          <rect
                            x={0}
                            y={yScale(emptyBar.label)}
                            width={plotWidth}
                            height={barHeight}
                            className={styles.emptyBar}
                            fill={`url(#${emptyBarPatternId}) #cbd5e0`}
                          />
                        </StackedBarTooltip>
                      </g>
                    );
                  })}
                </g>
              )}
            </g>
          </svg>
          {!shouldHideLegend && isPlotSizeValid && (
            <div
              className={styles.legendContainer}
              style={{
                marginLeft: `${labelWidth + Dimensions.BarLeftPadding}px`,
                maxHeight: Dimensions.LegendHeight,
              }}
            >
              <Legend
                items={segmentLabels.map((label) => ({
                  label,
                  color: colorScale(label),
                }))}
                formatLabel={formatLegendLabel}
              />
            </div>
          )}
        </div>
      </PlotLoadWrapper>
    </div>
  );
};
