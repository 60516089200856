import {
  Popover,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { SingleValue } from 'react-select';

import { useGetLoggedInUser } from '@revelio/auth';
import { FeatureFlag } from '@revelio/core';
import {
  NodeModalStateProvider,
  Option,
  SelectionCategories,
  useRestrictGenderAndEthnicityFilters,
} from '@revelio/filtering';
import { getCurrencySymbol } from '@revelio/replots';

import { useUnleashFlag } from '../../../shared/unleash-flags/use-unleash-flag';
import { FILTER_OPTIONS } from './filter-configuration-options';
import { FilterSelectDropdown } from './filter-select-dropdown';
import { SelectFilter } from './select-filter';
import { TalentDiscoveryFilter } from './selection-forms/talent-discovery-filter';
import { useTalentDiscoveryFilter } from './td-filter-provider';
import { TDNewTreeFilter } from './tdNewTreeFilter';
import {
  AI_SEARCH_FILTER_OPTION_VALUE,
  TalentDiscoveryFilterOption,
} from './types';

interface TalentDiscoveryFilterPopoverProps {
  trigger: ReactNode;
  selectedFilterToEdit?: TalentDiscoveryFilterOption;
  filterId?: string;
  onSubmitSavedFilterSet?: (value: SingleValue<Option> | undefined) => void;
}

export const getFilterOptions = (
  restrictGenderAndEthnicity: boolean,
  currencySelectFeatureFlag: boolean,
  currencySymbol?: string,
  exchangeRate?: number
) => {
  if (!currencySelectFeatureFlag && !restrictGenderAndEthnicity) {
    return FILTER_OPTIONS;
  }

  const reducedOptions = FILTER_OPTIONS.reduce((acc, cur) => {
    if (
      restrictGenderAndEthnicity &&
      [SelectionCategories.ETHNICITY, SelectionCategories.GENDER].includes(
        cur.value as SelectionCategories
      )
    ) {
      return acc;
    }

    const option =
      currencySelectFeatureFlag && cur.isCurrency
        ? { ...cur, prefix: currencySymbol, exchangeRate }
        : cur;

    return [...acc, option];
  }, [] as TalentDiscoveryFilterOption[]);

  return reducedOptions;
};

export const TalentDiscoveryFilterPopover = ({
  trigger,
  selectedFilterToEdit,
  filterId,
  onSubmitSavedFilterSet,
}: TalentDiscoveryFilterPopoverProps) => {
  const [localSelectedFilter, setSelectedFilter] =
    useState<TalentDiscoveryFilterOption | null>(null);

  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const aiSearchFilterFeatureFlag = useUnleashFlag(
    FeatureFlag.TdFiltersAiSearch
  );

  const currencySelectFeatureFlag = useUnleashFlag(FeatureFlag.CurrencySelect);

  const { loggedInUser } = useGetLoggedInUser();

  const currencySymbol = getCurrencySymbol(loggedInUser?.currency_data?.code);
  const exchangeRate = loggedInUser?.currency_data?.rate || 1;

  const selectedFilter = selectedFilterToEdit || localSelectedFilter;

  const handleOpenFilterMenu = () => {
    setIsFilterMenuOpen(true);
  };

  const {
    isAiSearchLoading,
    shouldTriggerOpenAiSearch,
    setShouldTriggerOpenAiSearch,
  } = useTalentDiscoveryFilter();

  useEffect(() => {
    if (shouldTriggerOpenAiSearch && !selectedFilter) {
      setIsFilterMenuOpen(true);
      setSelectedFilter(
        FILTER_OPTIONS.find(
          (option) => option.value === AI_SEARCH_FILTER_OPTION_VALUE
        ) || null
      );
      setShouldTriggerOpenAiSearch(false);
    }
  }, [
    shouldTriggerOpenAiSearch,
    selectedFilter,
    setSelectedFilter,
    setShouldTriggerOpenAiSearch,
  ]);

  const handleClose = useCallback(() => {
    if (isAiSearchLoading) {
      return;
    }

    setIsFilterMenuOpen(false);

    setTimeout(() => {
      setSelectedFilter(null);
    }, 100); // wait for the popover to close
  }, [isAiSearchLoading]);

  const handleCloseFilterMenu = () => {
    //opening nested tree triggers close without this
    if (
      selectedFilter &&
      selectedFilter.value !== SelectionCategories.INDUSTRY
    ) {
      return;
    }

    handleClose();
  };

  const restrictGenderAndEthnicity = useRestrictGenderAndEthnicityFilters();

  const filterOptions = getFilterOptions(
    restrictGenderAndEthnicity,
    currencySelectFeatureFlag,
    currencySymbol,
    exchangeRate
  );

  const filterOptionsWithMaybeAiSearch = aiSearchFilterFeatureFlag
    ? filterOptions
    : filterOptions.filter(
        (option) => option.value !== AI_SEARCH_FILTER_OPTION_VALUE
      );

  return (
    <Popover
      placement="bottom-start"
      closeOnBlur={!isAiSearchLoading}
      onClose={handleCloseFilterMenu}
      isOpen={isFilterMenuOpen}
      onOpen={handleOpenFilterMenu}
    >
      <PopoverTrigger>{trigger}</PopoverTrigger>
      <PopoverContent
        minWidth="325px"
        padding={3}
        color="navyBlue.500"
        boxShadow="2xl"
        data-testid={`td-filter-popover-content-${isFilterMenuOpen ? 'open' : 'closed'}`}
        width="fit-content"
      >
        {(() => {
          if (!isFilterMenuOpen) {
            return null;
          }

          if (!selectedFilter) {
            return (
              <SelectFilter
                filterOptions={filterOptionsWithMaybeAiSearch}
                setSelectedFilter={setSelectedFilter}
                closeMenu={handleCloseFilterMenu}
              />
            );
          }

          if (selectedFilter) {
            return (
              <div data-testid="filter-menu-popover">
                <PopoverHeader
                  marginBottom={2}
                  padding={0}
                  fontWeight="semibold"
                  borderBottomWidth={0}
                >
                  {!selectedFilterToEdit && (
                    <FilterSelectDropdown
                      filterOptions={filterOptionsWithMaybeAiSearch}
                      selectedFilter={selectedFilter}
                      onChangeSelectedFilter={setSelectedFilter}
                      isDisabled={isAiSearchLoading}
                    />
                  )}
                </PopoverHeader>
                {/* provider needed here to stop closing the popover when renaming a saved filter set */}
                <NodeModalStateProvider>
                  {selectedFilter.value === SelectionCategories.INDUSTRY ? (
                    <TDNewTreeFilter
                      selectionListIds={
                        selectedFilter.filterGranularities as SelectionCategories[]
                      }
                      onClose={handleClose}
                      selectedFilter={selectedFilter}
                      filterId={filterId}
                    />
                  ) : (
                    <TalentDiscoveryFilter
                      selectedFilter={selectedFilter}
                      closeMenu={handleClose}
                      filterId={filterId}
                      onSubmitSavedFilterSet={onSubmitSavedFilterSet}
                    />
                  )}
                </NodeModalStateProvider>
              </div>
            );
          }

          return null;
        })()}
      </PopoverContent>
    </Popover>
  );
};
