import classNames from 'classnames';
import {
  area,
  bisect,
  format as d3Format,
  extent,
  line,
  max,
  scaleLinear,
  scaleOrdinal,
} from 'd3';
import { isNumber } from 'lodash';
import { useRef, useState } from 'react';

import { CommonPlotProps, FormatType } from '../../types';
import {
  PRIMARY_COLOR,
  getFormatter,
  plotColors,
  useRenderCheck,
  useResizeObserver,
} from '../../utilities';
import { PlotLoadWrapper } from '../plot-loader/plot-loader';
import {
  PlotTooltip,
  TooltipHoverLine,
  TooltipPosition,
  useTooltipController,
} from '../plot-tooltip';
import styles from './kde-chart.module.css';
import { KdeTooltip, KdeTooltipProps } from './kde-tooltip';

const X_AXIS_HEIGHT = 12;
const TOP_PADDING = 1;
const PADDING_BOTTOM = 4;

type KdeValue = {
  // This is the value of the data we are measuring
  // ex. $100,000 salary
  value: number;
  // This is the density of the data point at this value
  // ex. 0.001 or 1% of salaries attributable to this value
  kde: number;
  // This is the cumulative distribution of the value up until this point
  // ex. 0.50 or 50% of salaries are less than $100,000
  cdf: number;
};

export type KdeData = {
  label: string;
  color?: string;
  values: KdeValue[];
};

type KdeChartProps = {
  data: KdeData[];
  colors?: string[];
} & CommonPlotProps;

/** Kernel Density Estimate */
export const KdeChart = ({
  data,
  colors = plotColors,
  format = FormatType.SI,
  loading = false,
  renderUpdate,
  currencyCode,
}: KdeChartProps) => {
  const { containerRef, width, height } = useResizeObserver();

  const plotWidth = width;
  const plotHeight = height - X_AXIS_HEIGHT - TOP_PADDING - PADDING_BOTTOM;
  const isPlotSizeValid = plotWidth > 0 && plotHeight > 0;

  const allValues = data.flatMap((d) => d.values.map((v) => v.value));

  const valueRange = extent(allValues);

  const xScale =
    isNumber(valueRange[0]) && isNumber(valueRange[1])
      ? scaleLinear([valueRange[0], valueRange[1]], [0, plotWidth])
      : null;

  const maxKde = max(data.flatMap((d) => d.values.map((v) => v.kde)));
  const yScale = maxKde
    ? scaleLinear([0, maxKde], [plotHeight, TOP_PADDING])
    : null;

  // Color Scale
  const colorScale = scaleOrdinal<string>()
    .domain(data.map((d) => d.label))
    .range(data.map((d, i) => d.color || colors[i]));

  const lineGenerator =
    xScale && yScale
      ? line<KdeValue>()
          .x((d) => xScale(d.value))
          .y((d) => yScale(d.kde))
      : null;

  const lines = data
    .map((d) => {
      if (!lineGenerator) return null;

      const path = lineGenerator(d.values);
      if (!path) return null;

      return { ...d, path };
    })
    .filter((d) => d !== null)
    .map((d, i) => ({ ...d, color: colorScale(d.label) || PRIMARY_COLOR }));

  const areaGenerator =
    xScale && yScale
      ? area<KdeValue>()
          .x((d) => xScale(d.value))
          .y0(yScale.range()[0])
          .y1((d) => yScale(d.kde))
      : null;

  const areas = data
    .map((d) => {
      if (!areaGenerator) return null;

      const path = areaGenerator(d.values);
      if (!path) return null;

      return { ...d, path };
    })
    .filter((d) => d !== null)
    .map((d, i) => ({ ...d, color: colorScale(d.label) || PRIMARY_COLOR }));

  const formatValue = getFormatter(format, {
    currencyCode,
  });
  const tooltipFormatType = (() => {
    if (format === FormatType.SI) return FormatType.INTEGER;
    if (format === FormatType.CURRENCY) {
      return FormatType.CURRENCY_INTEGER;
    }
    return format;
  })();
  const tooltipFormatValue = getFormatter(tooltipFormatType, {
    currencyCode,
  });

  const xTicks = xScale?.ticks(5).map((tick) => ({
    value: tick,
    label: formatValue(tick),
  }));

  const [tooltipValue, setTooltipValue] = useState<KdeTooltipProps | null>(
    null
  );
  const [tooltipPosition, setTooltipPosition] =
    useState<TooltipPosition | null>(null);

  const handleTooltipMove = (position: [number, number]) => {
    if (!xScale) return;

    const rawValue = xScale.invert(position[0]);
    const datasetIndex = bisect(
      data[0].values.map((v) => v.value),
      rawValue
    );
    const datasetValue = data[0].values[datasetIndex].value;
    const formattedValue = tooltipFormatValue(datasetValue);

    const values = data.map((d, i) => {
      const cdf = d.values[datasetIndex].cdf;
      const formattedCdf = d3Format('.1%')(cdf);

      return {
        label: d.label,
        color: colorScale(d.label) || PRIMARY_COLOR,
        description: `${formattedCdf} of people make up to ${formattedValue}`,
      };
    });

    setTooltipValue({ title: formattedValue, values });
    setTooltipPosition({ x: position[0], y: plotHeight / 2 });
  };

  const tooltipController = useTooltipController({
    onHover: handleTooltipMove,
  });

  const chartRef = useRef<SVGGElement>(null);
  useRenderCheck(chartRef, { renderUpdate });

  return (
    <div ref={containerRef} className={styles.container} data-testid="plot-KDE">
      <PlotLoadWrapper loading={loading} noData={data.length === 0}>
        <svg width="100%" height="100%">
          <g id="x-axis" data-testid="plot-x-axis">
            {isPlotSizeValid &&
              xScale &&
              xTicks &&
              xTicks.map((tick, i) => (
                <text
                  key={tick.value}
                  x={xScale(tick.value)}
                  y={plotHeight + X_AXIS_HEIGHT + PADDING_BOTTOM}
                  className={classNames(styles.xAxisLabel, {
                    [styles.firstTick]: i === 0,
                    [styles.lastTick]: i === xTicks.length - 1,
                  })}
                >
                  {tick.label}
                </text>
              ))}
          </g>
          <g
            id="chart"
            ref={chartRef}
            {...tooltipController.gProps}
            className={styles.chart}
          >
            {areas.map((area) => {
              if (!area) return null;

              return (
                <path
                  key={area.label}
                  className={`${styles['area-line-stroke']}`}
                  d={area.path}
                  stroke={area.color}
                  fill={area.color}
                  data-testid="area-path"
                />
              );
            })}
            {lines.map((line) => {
              if (!line) return null;

              return (
                <path
                  key={line.label}
                  className={`${styles.line}`}
                  d={line.path}
                  stroke={line.color}
                />
              );
            })}
            {tooltipController.isVisible && tooltipPosition && (
              <TooltipHoverLine x={tooltipPosition.x} height={plotHeight} />
            )}
            {isPlotSizeValid && (
              <rect
                x={0}
                y={0}
                width={plotWidth}
                height={plotHeight}
                fill="transparent"
              />
            )}
          </g>
        </svg>
        {tooltipPosition && (
          <PlotTooltip
            isVisible={tooltipController.isVisible}
            x={tooltipPosition.x}
            y={tooltipPosition.y}
          >
            {tooltipValue && (
              <KdeTooltip
                title={tooltipValue.title}
                values={tooltipValue.values}
              />
            )}
          </PlotTooltip>
        )}
      </PlotLoadWrapper>
    </div>
  );
};
