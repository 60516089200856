import { Card } from '@chakra-ui/react';

import {
  CompositionSummaryDataQuery,
  PostingsGetActiveQuery,
  SentimentGetSummaryDataQuery,
} from '@revelio/data-access';
import { FormatType, PlotLoadWrapper, StatTrendChart } from '@revelio/replots';

import { getStatChartData } from '../../utils';
import styles from './company-stats.module.css';

interface CompanyStatsProps {
  compositionData: CompositionSummaryDataQuery | undefined;
  postingsActiveData: PostingsGetActiveQuery | undefined;
  sentimentData: SentimentGetSummaryDataQuery | undefined;
  loading?: boolean;
  selectedCompanyRCID: number;
}

export const CompanyStats = ({
  compositionData,
  postingsActiveData,
  selectedCompanyRCID,
  sentimentData,
  loading = false,
}: CompanyStatsProps) => {
  const primaryCompositionData = {
    composition: compositionData?.composition?.filter(
      (datum) => datum?.metadata?.id === selectedCompanyRCID
    ),
  };

  const headcountData = primaryCompositionData
    ? getStatChartData({
        data: primaryCompositionData,
        metricName: 'headcount',
      })
    : null;

  const growthRateData = primaryCompositionData
    ? getStatChartData({
        data: primaryCompositionData,
        metricName: 'growth_rate',
      })
    : null;

  const primaryPostingData = {
    posting: postingsActiveData?.posting?.filter(
      (datum) => datum?.metadata?.id === selectedCompanyRCID
    ),
  };
  const postingData = primaryPostingData
    ? getStatChartData({ data: primaryPostingData, metricName: 'postings' })
    : null;

  const primarySentimentData = {
    sentiment2d: sentimentData?.sentiment2d?.filter(
      (datum) => datum?.metadata?.id === selectedCompanyRCID
    ),
  };
  const sentimentOverall = primarySentimentData
    ? getStatChartData({
        data: primarySentimentData,
        metricName: 'overallRating',
      })
    : null;

  const sentimentBusinessOutlook = primarySentimentData
    ? getStatChartData({
        data: primarySentimentData,
        metricName: 'businessOutlookRating',
      })
    : null;

  const sentimentCompensationsBenefits = primarySentimentData
    ? getStatChartData({
        data: primarySentimentData,
        metricName: 'compensationsBenefitsRating',
      })
    : null;

  const noData = [
    headcountData,
    growthRateData,
    postingData,
    sentimentOverall,
    sentimentBusinessOutlook,
    sentimentCompensationsBenefits,
  ].every((metric) => metric?.values?.length === 0);
  return (
    <Card height="100%" padding="16px 20px" variant="unstyled" width="100%">
      <PlotLoadWrapper loading={loading} noData={noData}>
        <div className={styles.container} data-testid="company-stats-plot-grid">
          <div className={styles.row}>
            <div className={styles.rowItem}>
              <StatTrendChart
                title="Headcount"
                info={
                  <p>
                    Our estimate of the total workforce. These counts include
                    both formal employees and contingent workers. These counts
                    also include subsidiaries. More information on the
                    methodologies used for this metric can be found{' '}
                    <a
                      className={styles.infoLink}
                      href="https://www.data-dictionary.reveliolabs.com/methodology.html#sampling-weights"
                    >
                      here
                    </a>
                    .
                  </p>
                }
                data={headcountData}
              />
            </div>
            <div className={styles.rowItem}>
              <StatTrendChart
                title="Growth"
                info="The growth rate is the percent change in the total workforce. It is
          the difference between the hiring rate and attrition rate."
                data={growthRateData}
                format={FormatType.PERCENTAGE}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.rowItem}>
              <StatTrendChart
                title="Active Postings"
                info="The number of active job postings."
                data={postingData}
              />
            </div>
            <div className={styles.rowItem}>
              <StatTrendChart
                title="Sentiment"
                info="The average score given by workers on the topic of Overall."
                data={sentimentOverall}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.rowItem}>
              <StatTrendChart
                title="Business Outlook"
                info="The average score given by workers on the topic of Business Outlook."
                data={sentimentBusinessOutlook}
              />
            </div>
            <div className={styles.rowItem}>
              <StatTrendChart
                title="Benefits"
                info="The average score given by workers on the topic of Compensation &
          Benefits."
                data={sentimentCompensationsBenefits}
              />
            </div>
          </div>
        </div>
      </PlotLoadWrapper>
    </Card>
  );
};
