import { Box, Button, Flex, Icon, Stack } from '@chakra-ui/react';
import { LegacyRef, forwardRef } from 'react';
import { FiSliders } from 'react-icons/fi';
import { SingleValue } from 'react-select';

import { useUserCurrency } from '@revelio/auth';
import { FeatureFlag } from '@revelio/core';
import { View } from '@revelio/data-access';
import {
  FilterSetSaveMenu,
  Option,
  useRestrictGenderAndEthnicityFilters,
} from '@revelio/filtering';

import { useUnleashFlag } from '../../../shared/unleash-flags/use-unleash-flag';
import { FilterTag } from './filter-tag';
import { AiSearchButton } from './selection-forms/ai-filter-search/ai-search-button';
import { EditAiSearchButton } from './selection-forms/ai-filter-search/edit-ai-search-button';
import {
  TalentDiscoveryFilterPopover,
  getFilterOptions,
} from './td-filter-popover';
import { useTalentDiscoveryFilter } from './td-filter-provider';
import { TalentDiscoveryRangeFilterState } from './td-filter-reducer';

const FilterButton = forwardRef(
  (props: { isDisabled?: boolean }, ref: LegacyRef<HTMLButtonElement>) => {
    return (
      <Button
        {...props}
        ref={ref}
        colorScheme="gray"
        borderRadius="4px"
        size="sm"
        paddingX={3}
        height="26px"
        backgroundColor="transparent"
        fontSize={'xs'}
        fontWeight={400}
        color="navyBlue.500"
        leftIcon={
          <Icon as={FiSliders} boxSize={3.5} transform="rotate(90deg)" />
        }
        data-testid="filter-menu-btn"
        isDisabled={props.isDisabled}
      >
        Filter
      </Button>
    );
  }
);

export type TalentDiscoveryFilterMenuProps = {
  onSubmitSavedFilterSet?: (value: SingleValue<Option> | undefined) => void;
};

const adjustCurrencyFilter = (
  filter: TalentDiscoveryRangeFilterState,
  exchangeRate?: number,
  currencySymbol?: string
) => {
  if (!filter.isCurrency || !exchangeRate) return filter;

  return {
    ...filter,
    start_value: filter.start_value && filter.start_value * exchangeRate,
    end_value: filter.end_value && filter.end_value * exchangeRate,
    symbol: currencySymbol,
  };
};

export const TalentDiscoveryFilterMenu = ({
  onSubmitSavedFilterSet,
}: TalentDiscoveryFilterMenuProps) => {
  const restrictGenderAndEthnicity = useRestrictGenderAndEthnicityFilters();
  const currencySelectFeatureFlag = useUnleashFlag(FeatureFlag.CurrencySelect);

  const { exchangeRate, currencySymbol } = useUserCurrency();

  const { state, dispatch, isAiSearchLoading, aiGeneratedFilterIds } =
    useTalentDiscoveryFilter();

  const filterOptions = getFilterOptions(
    restrictGenderAndEthnicity,
    currencySelectFeatureFlag,
    currencySymbol,
    exchangeRate
  );
  return (
    <Flex wrap="wrap" fontSize="sm" rowGap={2.5} alignItems="center">
      <Stack direction="row">
        {isAiSearchLoading ? <AiGenerating /> : <EditAiSearchButton />}

        {state.filters?.map((filter, index) => {
          const adjustedFilter = adjustCurrencyFilter(
            filter,
            exchangeRate,
            currencySymbol
          );
          const tag = (
            <FilterTag
              key={`${filter.name}-${index}`}
              filterOptions={filterOptions}
              filter={adjustedFilter}
              dispatch={dispatch}
              isDisabled={isAiSearchLoading}
            />
          );

          if (aiGeneratedFilterIds.includes(filter.id)) {
            return (
              <Box position="relative" cursor="pointer">
                <Box
                  position="absolute"
                  inset="0"
                  bgGradient="linear(to-r, rgba(28, 129, 223, 1) 0%, rgba(30, 217, 217, 1) 50%, rgba(148, 231, 118, 1) 100%)"
                  borderRadius="6px"
                  opacity={isAiSearchLoading ? 0.4 : 1}
                />
                <Box p={0.5} position={'relative'}>
                  {tag}
                </Box>
              </Box>
            );
          }
          return (
            <Flex border="2px solid white" borderRadius="4px">
              {tag}
            </Flex>
          );
        })}
        <Flex alignItems="center" gap={'1px'}>
          <TalentDiscoveryFilterPopover
            trigger={<FilterButton isDisabled={isAiSearchLoading} />}
            onSubmitSavedFilterSet={onSubmitSavedFilterSet}
          />
          <FilterSetSaveMenu
            view={View.TalentDiscovery}
            isDisabled={isAiSearchLoading}
          />
        </Flex>
      </Stack>
    </Flex>
  );
};

const AiGenerating = () => {
  const { handleStopAiSearch, setShouldTriggerOpenAiSearch } =
    useTalentDiscoveryFilter();

  const handleClose = () => {
    handleStopAiSearch();
    setShouldTriggerOpenAiSearch(true);
  };

  return (
    <AiSearchButton
      onClick={handleClose}
      isGenerating={true}
      tooltipLabel="Stop AI Search"
    />
  );
};
