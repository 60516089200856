import { ComponentProps } from 'react';

import { FeatureFlag, PageTitles } from '@revelio/core';

import { useUnleashFlag } from '../../shared/unleash-flags/use-unleash-flag';
import { Overview } from '../overview/overview';
import { OverviewV2 } from '../overview/overview-v2';

interface CompositionMigrationProps
  extends Omit<ComponentProps<typeof Overview>, 'title'> {
  titleV1: PageTitles[];
  titleV2: PageTitles[];
}

export const CompositionMigration = ({
  titleV1,
  titleV2,
  ...rest
}: CompositionMigrationProps) => {
  const useCompositionV2 = useUnleashFlag(FeatureFlag.CompositionV2) ?? true;

  if (useCompositionV2) {
    return <OverviewV2 title={titleV2} {...rest} />;
  }
  return <Overview title={titleV1} {...rest} />;
};
