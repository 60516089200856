import { Tooltip as ChakraTooltip, TooltipProps } from '@chakra-ui/react';

interface RevelioTooltipProps extends TooltipProps {
  label: string;
}

export const RevelioTooltip = ({ children, ...props }: RevelioTooltipProps) => {
  return (
    <ChakraTooltip
      hasArrow
      variant="label"
      maxWidth="170px"
      fontSize="xs"
      lineHeight="15px"
      openDelay={400}
      p={2}
      {...props}
    >
      {children}
    </ChakraTooltip>
  );
};
